import { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { AtomButton } from '../../atoms/AtomButton'
import { ContainerFluid } from '../../commons/Container'
import { Grid } from '../../commons/Grid'
import { Colors } from '../../commons/Theme'
import { getPrivacyValue, removeTrailingSlashes } from '../../commons/utils'
import {
  StyledMessaggioText,
  StyledWebformContainer,
  Text,
} from '../Webform/Webform.style'
import { ConsensoSection } from './components/ConsensoSection'
import { LiberatoriaPrivacyModal } from './components/LiberatoriaPrivacyModal'
import { SimpleHeader } from 'components/Header/SimpleHeader'
import { ThankYouPage } from './components/ThankYouPage'
import {
  StyledRicaricaMessaggioTitle,
  StyledSubmitButton,
} from './RicaricaForm.style'
import { Input } from 'commons/FormComponents/Input'

export const LiberatoriaForm = () => {
  const [name, setName] = useState('')
  const [surname, setSurname] = useState('')
  const [childName, setChildName] = useState('')
  const [childSurname, setChildSurname] = useState('')
  const [privacy, setPrivacy] = useState('')
  const [childPrivacy, setChildPrivacy] = useState('')
  const [isValidForm, setIsValidForm] = useState(false)
  const [disabledButton, setDisabledButton] = useState(false)
  const [error, setError] = useState('')
  const [submitted, setSubmitted] = useState(false)

  const [showModal, setShowModal] = useState(false)

  async function handleOnSubmit() {
    setDisabledButton(true)

    const IP = await fetch('https://api.ipify.org/?format=json')
      .then((res) => res.json())
      .then((res) => res.ip)
      .catch(() => {
        return 'ipnonrecuperato'
      })

    fetch(
      `${removeTrailingSlashes(
        process.env.A2A_DRUPAL_CASA_URL
      )}/a2a-api/create-liberatoria`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXNzd29yZCI6InojT2RsRTFKU3V4eXUmdVhlUl4mQTlkbm1vRkohaHVtbDNqVlRBOFRIY1kmeW9ASkhYIn0.riPIcy0C_FUY3XfzaIyxepkVITGwWxD71eC4bRVj6j4`,
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          AddressIP: IP,
          FirstName: name,
          LastName: surname,
          ConsentL0: getPrivacyValue(privacy),
          evento: 'JBP_liberatoria',
          nomeMinore: childName,
          cognomeMinore: childSurname,
          minorenne: getPrivacyValue(childPrivacy),
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.status) {
          setSubmitted(true)
        } else {
          setError('Qualcosa é andato storto.')
        }
      })
      .catch((err) => {
        setError('Qualcosa é andato storto.')
        console.error(err)
      })
      .finally(() => {
        setDisabledButton(false)
      })
  }

  useEffect(() => {
    if (name && surname && privacy) {
      setIsValidForm(true)
      return
    }
    setIsValidForm(false)
  }, [name, surname, privacy])

  if (submitted) {
    return <ThankYouPage />
  }

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <SimpleHeader />
      <StyledWebformContainer>
        <ContainerFluid>
          <Grid
            cols={1}
            desktopCols="1fr 2fr"
            colsLargeGap="120px"
            rowsGap="40px"
          >
            <RicaricaMessaggio />
            <div>
              <Grid
                cols={1}
                tabletCols={2}
                rowsGap="40px"
                colsDesktopGap="28px"
              >
                <Input
                  label="Nome"
                  value={name}
                  onChange={(e) => setName(e)}
                  placeholder="Nome"
                  required={true}
                />
                <Input
                  label="Cognome"
                  value={surname}
                  onChange={(e) => setSurname(e)}
                  placeholder="Cognome"
                  required={true}
                />
              </Grid>
              <ConsensoSection
                style={{ marginTop: '40px' }}
                message={
                  <>
                    Dichiaro di aver preso visione dell'
                    <a onClick={() => setShowModal(true)}>
                      informativa privacy
                    </a>{' '}
                    in conformità alle previsioni contenute nel Regolamento UE
                    2016/679 (Regolamento Privacy) e avendone compreso il
                    contenuto:
                  </>
                }
                footer={
                  <>
                    al trattamento delle proprie immagini fotografiche e delle
                    videoripresecompresa la diffusione delle stesse per le
                    finalità e con le modalità sopra descritte;
                  </>
                }
                name="privacy"
                onChange={(e) => {
                  setPrivacy(e.target.value)
                }}
              />
              <ConsensoSection
                message={<>IN CASO DI MINORE:</>}
                footer={
                  <>
                    <span>
                      al trattamento delle immagini fotografiche e delle
                      videoriprese compresa la diffusione delle stesse,del
                      proprio figlio minorenne,
                    </span>
                    <input
                      placeholder="Nome"
                      required
                      value={childName}
                      onChange={(e) => {
                        setChildName(e.target.value)
                      }}
                    />
                    <input
                      placeholder="Cognome"
                      required
                      value={childSurname}
                      onChange={(e) => {
                        setChildSurname(e.target.value)
                      }}
                    />
                    <span>
                      per le finalità e con le modalità sopra descritte.
                    </span>
                  </>
                }
                name="privacy-minorenne"
                onChange={(e) => {
                  setChildPrivacy(e.target.value)
                }}
              />
              <Text
                style={{ marginTop: '40px' }}
                $size={12}
                $color={Colors.grey700}
                $align="right"
              >
                *Campi obbligatori
              </Text>
              <StyledSubmitButton>
                <AtomButton
                  label="Salva 🚀"
                  disabled={!isValidForm || disabledButton}
                  onClick={handleOnSubmit}
                />
              </StyledSubmitButton>
              {error && (
                <Text
                  style={{ marginTop: '8px' }}
                  $size={14}
                  $color={Colors.error}
                  $align="right"
                >
                  {error}
                </Text>
              )}
            </div>
          </Grid>
        </ContainerFluid>
      </StyledWebformContainer>
      <LiberatoriaPrivacyModal
        showModal={showModal}
        onClose={() => setShowModal(false)}
      />
    </div>
  )
}

function RicaricaMessaggio() {
  return (
    <div>
      <StyledRicaricaMessaggioTitle>
        Liberatoria uso immagini/voce (maggiorenne/minorenne) 📸
      </StyledRicaricaMessaggioTitle>
      <StyledMessaggioText>
        A2A Life Company. Ogni giorno ci occupiamo di ambiente, acqua energia.
        Mettiamo a disposizione servizi essenziali per rispondere alle esigenze
        degli stili di vita contemporanei, nel rispetto di una sostenibilità di
        lungo periodo.
      </StyledMessaggioText>
    </div>
  )
}
