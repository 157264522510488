import Modal from 'components/Modale/Modal'
import { ModalProps } from '../../Modale/SearchModal/SearchModal.types'
import {
  StyledPrivacyHTML,
  StyledPrivacyModalWrapper,
} from '../RicaricaForm.style'
import { eventDataLayer, getGA4PageSezione } from 'commons/utils'
import { useEffect } from 'react'

export const LiberatoriaPrivacyModal = ({ showModal, onClose }: ModalProps) => {
  useEffect(() => {
    if (showModal) {
      eventDataLayer({
        event: 'modale',
        azione: 'modale_impression',
        stream: 'web',
        modale_name: 'privacy',
        position:
          getGA4PageSezione() === 'www' ? 'generic' : getGA4PageSezione(),
        sezione: getGA4PageSezione(),
      })
    }
  }, [showModal])

  return (
    <Modal show={showModal} onClose={onClose} title="Privacy">
      <StyledPrivacyModalWrapper>
        <StyledPrivacyHTML>
          <PrivacyPdfHtml />
        </StyledPrivacyHTML>
      </StyledPrivacyModalWrapper>
    </Modal>
  )
}

function PrivacyPdfHtml() {
  return (
    <>
      <h4>Informativa sul trattamento dei dati personali</h4>
      <p>
        Ai sensi degli art. 13 e 14 del Regolamento UE 2016/679 del 27 aprile
        2016
        <sup>
          <a href="#fn1">1</a>
        </sup>
        (di seguito “Regolamento Privacy”), Le forniamo le seguenti informazioni
        in merito al trattamento
        <sup>
          <a href="#fn2">2</a>
        </sup>{' '}
        dei Suoi dati personali.
      </p>
      <h5>1. Chi tratta i dati personali?</h5>
      <p>
        Titolari del trattamento dei dati personali sono A2A S.p.A. con sede
        legale a Brescia in via Lamarmora n. 230 e sede direzionale e
        amministrativa a Milano in corso di Porta Vittoria n. 4 e A2A Energia
        S.p.A. con sede legale a Milano in Corso di Porta Vittoria 4.
      </p>
      <h5>2. Chi si può contattare?</h5>
      <p>
        Per tutte le questioni relative al trattamento dei dati personali e
        all'esercizio dei suoi diritti, può contattare il Responsabile della
        Protezione dei Dati personali (RPD), all'indirizzo di posta elettronica{' '}
        <a href="mailto:dpo.privacy@a2a.it">dpo.privacy@a2a.it</a>.
      </p>
      <h5>3. Perché vengono trattati i dati personali?</h5>
      <p>
        <table>
          <tr>
            <th>Finalità del trattamento</th>
            <th>Base giuridica del trattamento</th>
          </tr>
          <tr>
            <td>
              Il Titolare partecipa al Jova beach party 2022 e all'interno
              dell'evento organizza presso il proprio stand attività correlate
              allo svolgimento di tale iniziativa. Queste attività sono
              documentate con scatti fotografici e/o videoriprese
            </td>
            <td>
              L'esecuzione del contratto e/o il legittimo interesse del Titolare
              a documentare l'attività aziendale.
            </td>
          </tr>
          <tr>
            <td>
              Le riprese fotografiche e/o videoriprese potranno essere trattate
              e pubblicate, in versione integrale o parziale, sui mezzi di
              comunicazione del Titolare del trattamento o di soggetti terzi.
            </td>
            <td>
              Il consenso liberamente espresso alla pubblicazione delle
              immagini.
            </td>
          </tr>
          <tr>
            <td>
              Il trattamento potrebbe essere finalizzato a rispondere alle
              richieste provenienti dalle Autorità ed enti competenti, ecc...
            </td>
            <td>L'adempimento di un obbligo di legge.</td>
          </tr>
        </table>
      </p>
      <h5>5. Quali dati personali sono trattati?</h5>
      <p>
        Possono essere trattate le seguenti categorie di dati:
        <ul>
          <li>dati identificativi e anagrafici (nome, cognome);</li>
          <li>
            dati relativi all'immagine (es. scatti fotografici e/o
            videoriprese);
          </li>
          <li>altri dati riconducibili alle categorie sopra indicate.</li>
        </ul>
      </p>
      <h5>5. Come sono trattati i dati?</h5>
      <p>
        Il trattamento è effettuato dal personale autorizzato nell'espletamento
        delle proprie attività, con o senza l'ausilio di strumenti elettronici,
        secondo principi di liceità e correttezza in modo da tutelare in ogni
        momento la riservatezza e i diritti dell'interessato.
      </p>
      <h5>6. A chi sono comunicati i dati personali?</h5>
      <p>
        I suoi dati personali possono essere messi a disposizione di:
        <ul>
          <li>
            soggetti che supportano il titolare nelle attività di fotografia o
            videoripresa, società di servizi informatici, società di servizi
            social media management che agiranno come Responsabili del
            trattamento;
          </li>
          <li>
            altre Società del Gruppo A2A che agiranno a seconda dei casi come
            Titolari o Responsabili del trattamento
          </li>
          <li>
            Autorità ed enti competenti (ove richiesto) che agiranno quali
            Titolari dei trattamenti.
          </li>
        </ul>
        I dati personali comprese le immagini, previo Suo espresso consenso,
        potranno essere diffusi:
        <ul>
          <li>
            tramite pubblicazione su carta stampata (es. giornali, riviste,
            ecc.);
          </li>
          <li>in occasione di mostre, convegni ed eventi;</li>
          <li>
            con strumenti elettronici (es. siti internet delle società del
            Gruppo A2A, Intranet, monitor presenti in alcune sedi aziendali,
            blog, pagina facebook, Tik-Tok, canale YouTube, Linkedin, proiezioni
            video, televisione, ecc.).
          </li>
        </ul>
      </p>
      <h5>7. I dati sono trasferiti in paesi terzi?</h5>
      <p>
        I suoi dati personali saranno trattati all'interno dello Spazio
        Economico Europeo (“SEE”). Qualora si rendesse necessario in via
        eccezionale il trasferimento dei suoi dati personali al di fuori dello
        SEE, tale trasferimento avverrà sulla base di una decisione di
        adeguatezza della Commissione Europea, se applicabile, o in presenza
        delle adeguate garanzie richieste dal Regolamento Privacy.{' '}
      </p>
      <h5>8. Per quanto tempo i dati sono conservati?</h5>
      <p>
        I Suoi dati verranno conservati per un periodo di tempo non superiore a
        quello necessario al conseguimento delle finalità per le quali essi sono
        trattati.
      </p>
      <h5>9. Quali sono i diritti che può esercitare?</h5>
      <p>
        Lei ha il diritto di chiedere al Titolare del trattamento:
        <ul>
          <li>
            la conferma che sia o meno in corso un trattamento di Suoi dati
            personali e, in tal caso, di ottenerne l'accesso (diritto di
            accesso);
          </li>
          <li>
            la rettifica dei dati personali inesatti o l'integrazione dei dati
            personali incompleti (diritto di rettifica);
          </li>
          <li>
            la cancellazione dei dati stessi se sussiste uno dei motivi previsti
            dal Regolamento Privacy (diritto all'oblio);
          </li>
          <li>
            la limitazione del trattamento quando ricorre una delle ipotesi
            previste dal Regolamento Privacy (diritto di limitazione);
          </li>
          <li>
            di ricevere in un formato strutturato, di uso comune e leggibile da
            dispositivo automatico i dati personali da Lei forniti al Titolare e
            di trasmettere tali dati a un altro Titolare del trattamento
            (diritto alla portabilità);
          </li>
          <li>
            di opporsi in qualsiasi momento al trattamento eseguito per il
            perseguimento di un legittimo interesse del titolare (diritto di
            opposizione);
          </li>
          <li>
            di revocare l'eventuale consenso al trattamento dei Suoi dati, in
            qualsiasi momento, senza pregiudicare la liceità del trattamento
            basata sul consenso prestato prima della revoca.
          </li>
        </ul>
        Per esercitare i Suoi diritti potrà inviare richiesta scritta al
        Titolare del trattamento o al Responsabile della Protezione dei Dati,
        indicando la Società del Gruppo A2A destinataria della richiesta. Fatto
        salvo ogni altro ricorso amministrativo o giurisdizionale, ha il diritto
        di proporre reclamo all'Autorità Garante per la protezione dei dati
        personali, qualora ritenga che il trattamento che la riguarda violi il
        Regolamento Privacy.
      </p>
      <h5>10. Da quale fonte hanno origine i dati personali?</h5>
      <p>
        I suoi dati personali saranno trattati all'interno dello Spazio
        Economico Europeo (“SEE”). Qualora si rendesse necessario in via
        eccezionale il trasferimento dei suoi dati personali al di fuori dello
        SEE, tale trasferimento avverrà sulla base di una decisione di
        adeguatezza della Commissione Europea, se applicabile, o in presenza
        delle adeguate garanzie richieste dal Regolamento Privacy.
      </p>
      <h5>11. I dati sono sottoposti a decisioni automatizzate?</h5>
      <p>
        I dati non saranno sottoposti a decisioni basate unicamente sul
        trattamento automatizzato, compresa la profilazione, che producano
        effetti giuridici che riguardano o che incidano significativamente sulla
        Sua persona.
      </p>
      <hr />
      <small id="fn1">
        <sup>1</sup> Regolamento generale sulla protezione dei dati (GDPR).
      </small>
      <small id="fn2">
        <sup>2</sup> Trattamento: qualsiasi operazione o insieme di operazioni,
        compiute con, o senza, l'ausilio di processi automatizzati e applicate a
        dati personali, o a insiemi di dati personali, come la raccolta, la
        registrazione, l'organizzazione, la strutturazione, la conservazione,
        l'adattamento o la modifica, l'estrazione, la consultazione, l'uso, la
        comunicazione mediante trasmissione, diffusione o qualsiasi altra forma
        di messa a disposizione, il raffronto o l'interconnessione, la
        limitazione, la cancellazione o la distruzione.
      </small>
    </>
  )
}
